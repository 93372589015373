<template>
  <div class="activity">
    <div style="height: 20px"></div>
    <div class="container">
      <h2>预约登记</h2>
      <div class="tips">
        请填写您的个人信息和具体需求，我们将会在24小时内与您联系
      </div>
      <div class="form_warp">
        <el-form ref="form" :model="form" label-width="150px">
         
          <template v-if="typeNum != 3">
             <p class="label"><span>*</span>请选择合适时间</p>
            <el-form-item label="请选择学习开始时间">
              <el-date-picker
                v-model="form.start_time"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="请选择学习结束时间">
              <el-date-picker
                v-model="form.end_time"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </template>
          <p class="label"><span>*</span>姓名</p>
          <el-form-item label="请填写您的姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <p class="label"><span>*</span>电话</p>
          <el-form-item label="请填写您的联系电话">
            <el-input v-model="form.phone" maxlength="11"></el-input>
          </el-form-item>
          <template v-if="typeNum != 3">
            <p class="label">单位</p>
            <el-form-item label="请填写您的工作单位">
              <el-input v-model="form.unit"></el-input>
            </el-form-item>
            <p class="label">邮箱</p>
            <el-form-item label="请填写您的邮箱">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
          </template>
          <p class="label">备注</p>
          <el-form-item label-width="0">
            <textarea
              v-model="form.remark"
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="请填写备注，选填"
            ></textarea>
          </el-form-item>
          <div class="deep_list flex">
            <p class="btn1 cursor" @click="restBtn">重置</p>
            <p class="btn2 cursor" @click="submitBtn">提交预约信息</p>
          </div>
        </el-form>
      </div>
    </div>

    <div class="sign_success" v-if="signState">
      <div class="sign_box">
        <div class="close_icon">
          <img src="@/assets/close-grey-ico.png" alt="" />
        </div>

        <div class="img">
          <img src="@/assets/pc/ok.png" alt="" />
        </div>
        <div class="title">提交成功</div>
        <div class="tips">{{ times }}s后自动返回</div>
        <!-- <div class="btn1 cursor" @click="$router.push('/userCenter/index?clickMenu=8')">意见反馈</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pc-home",
  components: {},
  data() {
    return {
      form: {
        start_time: "",
        end_time: "",
        name: "",
        did: "",
        phone: "",
        unit: "",
        email: "",
        remark: "",
      },
      signState: false,
      times: 3,
      typeNum: "",
      siteInfo:'',
    };
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.typeNum = this.$route.query.type;
    this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    this.form.did = this.siteInfo?this.siteInfo.id:''

  },
  methods: {
    submitBtn() {
      this.form.type = this.$route.query.type;
      if (this.typeNum != "3") {
        if (!this.form.start_time) {
          this.$message.error("请输入学习开始时间");
          return;
        }
        if (!this.form.end_time) {
          this.$message.error("请输入学习结束时间");
          return;
        }
        if (this.form.end_time<this.form.start_time) {
          this.$message.error("请选择正确的开始与结束时间");
          return;
        }
      }
      if (!this.form.name) {
        this.$message.error("请输入姓名");
        return;
      }
      if (!this.form.phone) {
        this.$message.error("请输入手机号");
        return;
      }
      this.$axios
        .post("https://kskapi.kskstudy.com/om/v1/teacherSubscribeForm", this.form)
        .then((res) => {
          if (res.code == 0) {
            this.signState = true;
            let downTime = setInterval(() => {
              if (this.times > 0) {
                this.times--;
              } else {
                clearInterval(downTime);
                // this.$router.replace("/index");
                this.$router.go(-1)
              }
            }, 1000);
          }
        });
    },
    restBtn() {
      for (let i in this.form) {
        this.form[i] = "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.activity {
  background: #f6f6f6;
  padding-bottom: 148px;
  .container {
    width: 1200px;
    margin: 20px auto;
    background: #fff;
    padding-bottom: 58px;
    h2 {
      text-align: center;
      padding-top: 60px;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    div.tips {
      width: 549px;
      height: 60px;
      background: #f9f9f9;
      border-radius: 5px 5px 5px 5px;
      margin: 27px auto 0;
      padding-left: 27px;
      line-height: 60px;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
    // 输入框
    .form_warp {
      width: 534px;
      margin: 66px auto 0;
      p.label {
        font-size: 14px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        span {
          color: #e15416;
        }
        margin-bottom: 28px;
      }
      textarea {
        border: 1px solid #eeeeee;
        resize: none;
        width: 534px;
        height: 163px;
        padding: 16px 18px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333;
      }
      .deep_list {
        justify-content: center;
        p {
          text-align: center;
          width: 120px;
          height: 32px;
          line-height: 32px;
          border-radius: 2px;
          font-size: 14px;
        }
        .btn1 {
          background: #e5f0fc;

          color: #0065df;
        }
        .btn2 {
          background: #0065df;
          color: #fff;
          margin-left: 15px;
        }
      }
    }
  }
  // 签到成功弹框
  .sign_success {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    div.img {
      width: 100%;
      background: url(~@/assets/pc/box_bgm.png) no-repeat;
      background-position: center;
      img {
        display: block;
        margin: 0 auto;
      }
    }
    .sign_box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 346px;
      margin: 32px auto 0;
      padding: 32px 0;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      div.close_icon {
        position: absolute;
        right: 31px;
        top: 28px;
        width: 13px;
        height: 13px;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      div.title {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        margin-top: 30px;
      }
      div.tips {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        padding: 30px;
      }
      div.btn1 {
        text-align: center;
        color: #0065df;
        font-size: 14px;
      }
    }
  }
}
</style>